<template>
  <banner class="mb-4" :backgroundImage="$maxxpm.config.faq.banner.image">
    <div class="pt-5 pb-5">
      <h1 class="text-white text-center">Frequently Asked Questions</h1>
    </div>
  </banner>
  <i class="pi pi-spinner pi-spin" v-if="loading"></i>
  <p v-else-if="showError" class="text-center">Sorry, something went wrong. Please try again later</p>
  <div v-else class="questions-container">
    <h2 class="display-6 text-primary text-center">Click on a Question Below</h2>
    <Accordion>
      <AccordionTab v-for="(faq, index) in faqs" :key="'faq-' + index" :header="faq.question">
        <p v-if="faq.links" class="text-start" v-html="faq.answer"></p>
        <p v-else class="text-start">{{ faq.answer }}</p>
        <div v-if="faq.list">
          <ul v-if="typeof faq.list[0] === 'object'">
            <li v-for="li in faq.list" :key="li.answer" class="text-start">
              {{ li.answer }}
              <ul class="mt-1">
                <li v-for="sli in li.sublist" :key="sli" class="text-start">
                  <p v-if="faq.links" v-html="sli" class="m-0"></p>
                  <p v-else class="m-0">{{ sli }}</p>
                </li>
              </ul>
            </li>
          </ul>
          <ul v-else>
            <li v-for="li in faq.list" :key="li" class="text-start">
              <p v-if="faq.links" v-html="li" class="m-0"></p>
              <p v-else class="m-0">{{ li }}</p>
            </li>
          </ul>
        </div>
        <p class="text-start">{{ faq.footnote }}</p>
      </AccordionTab>
    </Accordion>

    <h2 class="mt-3" role="heading">Other Important Information, Per Your Lease:</h2>
    <ul>
      <li v-for="li in otherInfo" :key="li" class="text-start">
        <p v-html="li"></p>
      </li>
    </ul>
    <p class="mt-5 text-center">These policies are subject to change at any time.</p>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import axios from "axios";
import { Banner } from "@maxxpm/vue-components";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

const $maxxpm = inject("$maxxpm");

const faqs = ref([]);
const loading = ref(false);
const showError = ref(false);

onMounted(async () => {
  try {
    loading.value = true;
    faqs.value = (await axios.get("https://cdn.maxxpm.com/resources/faq.json")).data;

    const dnaQuestion = faqs.value.findIndex((qanda) => qanda.question === "Why does my dog have to be DNA tested?");
    if (dnaQuestion) {
      if ($maxxpm.config.faq.dnaRequired) {
        faqs.value[dnaQuestion].answer =
          "The HOA requires DNA testing. The fine for leaving feces in the community is $500 plus a $50 service of notice fee.";
        delete faqs.value[dnaQuestion].list;
      } else {
        faqs.value.splice(dnaQuestion, 1);
      }
    }

    const qual = faqs.value.findIndex((qanda) => qanda.question === "What are the rental qualifications?");
    if (qual) {
      faqs.value[qual].answer = "You can find our Rental Qualifications <a href='/#qualifications'>HERE</a>.";
    }
  } catch (error) {
    showError.value = true;
  } finally {
    loading.value = false;
  }
});

const otherInfo = ref([
  "Per your lease, the rent is late after the 3rd of the month. Late fees will NOT be waived for ANY reason.",
  "If your payment bounces TWICE, then we do cut off access to pay online and you will be required to pay via certified funds for the remainder of your tenancy.",
  "When you pay rent via your tenant portal it posts to your ledger immediately then takes up to 5 business days to pull from your bank account.",
  "Please see your welcome email for HOA rules, internet info, and other important info.",
  "It is your responsibly to report any maintenance issues to MAXX PM via your tenant portal. You will be financially liable for any unreported issues.",
  "If a vendor calls for a service appointment and you ignore, refuse, or no-show, then you will be liable for the minimum $105 trip fee plus any repair charges.",
  "For any electrical issues please reset all GFCIs and breakers before you submit a maintenance request.  Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "You are responsible for all light bulbs, air filters, and batteries in the unit. Please replace those before reporting an issue with the affected item. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting a furnace or A/C issue be sure the filter is clean, nothing is blocking the return air vents, and the breaker is on. Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting no hot water, please light your water heater. If the water heater is electric you need to reset the breaker. If the water heater is gas you need to follow the directions on the water heater. Please see our website for information videos. If the vendor finds this is the issue, then you will be billed.",
  "Before reporting a garbage disposal issue, please use the tool underneath the disposal to break free any blockage and or press the red/black reset button at the bottom of the disposal. You may also see our website for information videos. If you’ve clogged/broke the disposal then you will be charged for the repair.",
  "If you have noise issues, please notify the police. If you have aggressive or loose animal issues, please notify animal control. Please notify MAXX PM as well so we can take proper action where needed.",
  "If you are locked out of your unit, please call a locksmith. If you want to purchase another key, you can request that through your property managers. We can make a copy and charge you for a new key only by your visiting our office during office hours.",
  "For after business hours emergencies <strong>(ONLY: no heat in winter or a flood) please call our after-hours service at 855-240-8412</strong>.",
]);
</script>

<style scoped>
.questions-container {
  width: 95%;
  max-width: 1200px;
  margin: auto;
}
</style>
